export const customerReviews = [
  {
    name: "Esther Howard",
    location: "Texas, USA",
    message:
      "Epicize transformed my healthcare experience! The online consultations were so convenient, and the doctors were knowledgeable and caring.",
  },
  {
    name: "John Doe",
    location: "California, USA",
    message:
      "I found the perfect specialist for my condition through Epicize. The personalized treatment plan made all the difference. Thank you for prioritizing my health!",
  },
  {
    name: "Alice Smith",
    location: "New York, USA",
    message:
      "Booking appointments was a breeze, and the service exceeded my expectations. The doctors truly listen and provide effective solutions. 5 stars!",
  },
  {
    name: "Bob Johnson",
    location: "Florida, USA",
    message:
      "Epicize is a game-changer! The emergency care saved me during a critical situation. Grateful for their quick and efficient support.",
  },
  {
    name: "Jane Brown",
    location: "Washington, USA",
    message:
      "I used to dread dental visits, but Epicize made it a pleasant experience. The dentist was gentle and professional. I'll definitely be back!",
  },
  {
    name: "Robert Wilson",
    location: "Texas, USA",
    message:
      "Finally, a healthcare platform that puts patients first! The heart disease service provided comprehensive care and regular follow-ups. Thank you, Epicize!",
  },
  {
    name: "Mary Lee",
    location: "California, USA",
    message:
      "I've been using Epicize for a variety of health concerns, and each time, I received exceptional care. It's my go-to for all medical needs!",
  },
  {
    name: "David Miller",
    location: "Texas, USA",
    message:
      "I highly recommend Epicize for online consultations. It's convenient, secure, and the doctors are top-notch. Great job, team!",
  },
  {
    name: "Sarah Johnson",
    location: "Florida, USA",
    message:
      "The convenience of accessing medical notes online was fantastic. Epicize made managing my health records hassle-free! Best Healthcare services.",
  },
  {
    name: "Michael Brown",
    location: "New York, USA",
    message:
      "As a busy professional, Epicize has been a lifesaver. Quick prescriptions and refills without compromising on quality care. Thank you!",
  },
];
